import {
  Box,
  Button, Flex, Heading, Img, TabList, TabPanel, TabPanels, Tabs, Text,
} from '@chakra-ui/react';
import { useMemo, useState } from 'react';
import {
  Else, If, Then, When,
} from 'react-if';
import { useParams } from 'react-router-dom';
import { useSearchParam } from 'react-use';

import { ActivityIcon } from './components/ActivityIcon';
import { FileIcon } from './components/FileIcon';
import { FileViewTabs } from './components/FileViewTabs';
import { TransferOverview } from './components/TransferOverview';
import { WorkOrderOverview } from './components/WorkOrderOverview';
import FileListContext from './FileListContext';
import { ProjectOverview } from '../project-overview-page/components/ProjectOverview';
import { ActivitiesTable } from '../projects-page/ActivitiesTable';
import { signOut } from 'api/auth';
import DeleteIcon from 'assets/icons/delete-black.svg';
import TransferIcon from 'assets/icons/transfer.svg';
import { AsyncWrapper } from 'components/AsyncWrapper';
import AsyncButton from 'components/form/AsyncButton';
import { NavigationTabs } from 'components/NavigationTabs';
import { TransferDeleteModal } from 'components/table/TransferDeleteModal';
import { TabWithIcon } from 'components/TabWithIcon';
import useAxios from 'hooks/useAxios';
import useUserContext from 'hooks/useUserContext';
import {
  Files,
  TransferWithMeta,
} from 'types/api';
import { isExpired } from 'utils/file';

export function TransferPage(): JSX.Element {
  const { transferId } = useParams();
  const [transferRequest] = useAxios<TransferWithMeta>(`/file-api/transfers/${transferId}`);
  const [transferFileListRequest, fetchTransferFileList] = useAxios<Files[]>(`/file-api/transfers/${transferId}/file-list`);
  const [tabIndex, setTabIndex] = useState(0);
  const transfer = transferRequest.data;

  const { user, isStudioMember } = useUserContext();
  const requestedEmailAddress = useSearchParam('email');

  const handleReauthenticate = async (): Promise<void> => {
    const paramProps: Record<string, string> = {
      redirectTo: `/transfers/transfers/${transferId}`,
    };
    if (requestedEmailAddress) {
      paramProps.email = requestedEmailAddress;
    }

    const searchParams = new URLSearchParams(paramProps);
    await signOut(searchParams);
  };

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const { expired } = useMemo(() => {
    if (!transfer) return { expired: false, expiresAt: '' };
    return isExpired({
      DateCreated: transfer.DateCreated,
      BlobDeleted: transfer.BlobsDeleted,
    });
  }, [transfer]);

  const emailText = (email: string): JSX.Element => (
    <Text
      as='span'
      fontWeight='700'
      textDecoration='underline'
    >
      {email}
    </Text>
  );

  const filePageContext = useMemo(() => ({
    isReadOnly: expired,
    fileList: transferFileListRequest.data || [],
    fetchFileList: fetchTransferFileList,
    isArchive: false,
  }), [expired, fetchTransferFileList, transferFileListRequest.data]);

  return (
    <If condition={requestedEmailAddress && user?.EmailAddress !== requestedEmailAddress}>
      <Then>
        <Flex
          justifyContent='center'
        >
          <Box
            maxWidth='800px'
            minWidth='600px'
            bgColor='gray.800'
            padding='40px 60px'
            borderRadius={15}
          >
            <Flex direction='column' alignItems='left' gap='20px'>
              <Heading fontSize='23px'>Transfer Access Notice</Heading>
              <Text>
                You are trying to access a transfer from an email that was sent to {emailText(requestedEmailAddress!)},
                but you are currently logged in as {emailText(user?.EmailAddress!)}.
              </Text>
              <Text>To access this transfer, please log out and log back in using {emailText(requestedEmailAddress!)}.</Text>
              <Box
                mt='25px'
                display='inline-block'
              >
                <AsyncButton
                  onClick={handleReauthenticate}
                  padding='15px 25px'
                >
                  <Text>
                    Sign in as {emailText(requestedEmailAddress!)}
                  </Text>
                </AsyncButton>
              </Box>
            </Flex>
          </Box>
        </Flex>
      </Then>
      <Else>
        <AsyncWrapper requests={[transferRequest, transferFileListRequest, { loading: !transfer && !transferFileListRequest.data }]}>
          <FileListContext.Provider value={filePageContext}>
            <Flex direction='column' gap='50px' width='100%' justifyContent='center'>
              <NavigationTabs tabName='transfers' hideSearchBar disableSelectedStyling />
              <Flex justifyContent='space-between'>
                <Flex height='30px' align='center' gap='10px'>
                  <Img src={TransferIcon} w='20px' h='15px' />
                  <Heading fontWeight='700' fontSize='20px'>Transfer #{transferId}: Sources</Heading>
                </Flex>
                <When condition={!transfer?.Canceled}>
                  <Button
                    onClick={() => setIsDeleteDialogOpen(true && !transfer?.Canceled)}
                    leftIcon={<Img src={DeleteIcon} h='15px' />}
                    height='54px'
                  >
                    Delete Transfer
                  </Button>
                </When>
              </Flex>
              <Flex direction='column' gap='25px'>
                <When condition={!!transfer}>
                  <ProjectOverview projectId={transfer?.Project.ProjectNumber} />
                  <TransferOverview transferId={transferId} />
                  <When condition={!!transfer?.WorkOrderIdentifier && !!isStudioMember}>
                    <WorkOrderOverview
                      projectIdentifier={transfer?.Project.ProjectIdentifier!}
                      workOrderIdentifier={transfer?.WorkOrderIdentifier!}
                    />
                  </When>
                </When>
                <When condition={transfer?.Canceled}>
                  <Flex
                    justifyContent='center'
                    alignItems='center'
                    height='50px'
                    fontSize='20px'
                    fontWeight='700'
                    background='darkGold.1000'
                  >
                    This transfer has been deleted.
                  </Flex>
                </When>
                <When condition={expired}>
                  <Flex
                    justifyContent='center'
                    alignItems='center'
                    height='50px'
                    fontSize='20px'
                    fontWeight='700'
                    background='darkGold.1000'
                  >
                    This transfer has expired.
                  </Flex>
                </When>
              </Flex>
              <Tabs isLazy lazyBehavior='unmount' index={tabIndex} onChange={setTabIndex}>
                <TabList>
                  <TabWithIcon
                    icon={FileIcon()}
                    label='Files'
                  />
                  <TabWithIcon
                    icon={ActivityIcon()}
                    label='Activities'
                  />
                </TabList>
                <TabPanels>
                  <FileViewTabs transfer={transfer} />
                  <TabPanel>
                    <ActivitiesTable transferId={transferId} />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Flex>
          </FileListContext.Provider>
          <When condition={isDeleteDialogOpen}>
            <TransferDeleteModal
              transferId={transferId!}
              transferName={transfer?.TransferName}
              isOpen={isDeleteDialogOpen}
              onClose={() => setIsDeleteDialogOpen(false)}
            />
          </When>
        </AsyncWrapper>
      </Else>
    </If>
  );
}
