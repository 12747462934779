import {
  Route, Routes,
} from 'react-router-dom';

import useAdminRoutes from './useAdminRoutes';
import useAuthRoutes from './useAuthRoutes';
import useBookingRoutes from './useBookingRoutes';
import usePortalRoutes from './usePortalRoutes';
import useTransfersRoutes from './useTransfersRoutes';
import ErrorPage from '../components/error/ErrorPage';
import DevPage from '../pages/dev/DevPage';

export default function RouteIndex(): JSX.Element {
  const bookingRoutes = useBookingRoutes();
  const transferRoutes = useTransfersRoutes();
  const adminRoutes = useAdminRoutes();
  const authRoutes = useAuthRoutes();
  const portalRoutes = usePortalRoutes();

  return (
    <Routes>
      <Route path='*' element={<ErrorPage title='Page not found' />} />
      {authRoutes}
      {transferRoutes}
      {bookingRoutes}
      {adminRoutes}
      {portalRoutes}
      <Route
        path='/dev'
        element={<DevPage />}
      />
    </Routes>
  );
}
